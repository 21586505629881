import React, { useState, useContext, useEffect } from "react";
import ENFlag from "../../../images/EN-flag.png";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import { toast } from "react-toastify";
import { AiOutlineCloudUpload } from "react-icons/ai";
import { RiCloseCircleFill } from "react-icons/ri";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import ButtonUI from "../../material-ui/ButtonUI";
import { AuthContext } from "../../../context/Auth/AuthContext";
import requestedDocumentPopup from "../../../api/requestDocumentPopup";
import "../../../styles/RequestDocumentsPopup.css";
import saveRequestedDocument from "../../../api/saveRequestedDocument";
import getBeneficiary from "../../../api/getBeneficiary";
import getServiceList from "../../../api/getServiceList";
import baseUrl from "../../../config/baseUrl";
import { Box, Typography } from "@mui/material";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = ({ children, onClose, ...other }) => (
  <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
    {children}
    {onClose && (
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <RiCloseCircleFill />
      </IconButton>
    )}
  </DialogTitle>
);

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const RequestDocumentsPopup = ({
  clientID,
  clientPrimaryID,
  status,
  documentTypeArray,
  setCategoryUpdatedToTrue,
  clientType,
  setSelectedClientId,
  clientSubType,
  emailsToBeSent,
}) => {
  const [language, setLanguage] = useState("EN");
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [tags, setTags] = useState([]);
  const [selectedDocumentRequestedStage, setSelectedDocumentRequestedStage] =
    useState(clientID.primary ? clientSubType : []);
  const [contactList, setContactList] = useState([]);
  const [stagesList, setStagesList] = useState([]);
  const [selectedContacts, setSelectedContacts] = useState([]);
  const { token, user_id } = useContext(AuthContext);
  const [email, setEmail] = useState(emailsToBeSent || ""); // Initialize correctly
  const [isEmailEditable, setIsEmailEditable] = useState(
    clientID.email ? false : true
  );
  const [emailErrors, setEmailErrors] = useState([]); // Track contacts without emails

  useEffect(() => {
    setEmail(emailsToBeSent || "");
    setIsEmailEditable(emailsToBeSent ? false : true);
  }, [emailsToBeSent]);

  const fetchContacts = async () => {
    const url = `${baseUrl}/api/get-client-link-data?contact_id=${clientPrimaryID}`;
    try {
      const response = await getBeneficiary({ api: url, token });
      const contacts = [...response.clients];
      setContactList(contacts);
      if (contacts.length > 0) {
        setSelectedContacts([contacts[0]]);
      }
    } catch (err) {
      console.error("Error while fetching contact list", err);
    }
  };

  const fetchStages = async () => {
    const url = `${baseUrl}/api/get-document-req-stages?contact_id=${clientPrimaryID}`;
    try {
      const response = await getServiceList({ api: url, token });
      const requestedDocumentStageArray = [
        "All",
        ...response.document_stages.map((stage) => stage),
      ];
      setStagesList(requestedDocumentStageArray);
    } catch (err) {
      console.error("Error while fetching stages", err);
    }
  };

  const onRequestDocumentInPopupFunc = async () => {
    setLoading(true);
    try {
      const onRequestDocumentInPopupResponse = await requestedDocumentPopup({
        token,
        stage: selectedDocumentRequestedStage,
        clientID: clientID.id,
      });

      if (onRequestDocumentInPopupResponse.code === 200) {
        const requiredTagsData =
          onRequestDocumentInPopupResponse?.data?.length === 0
            ? []
            : onRequestDocumentInPopupResponse?.data?.map((doc) => ({
                contact_id: clientID.id,
                document_name: doc.document_name,
                document_category: doc.request_type,
                user_id,
                document_request_setting_id: doc.document_request_setting_id,
                email_id: email,
              }));
        setTags(requiredTagsData);
      } else {
        setTags([]);
      }
    } catch (err) {
      console.error("Error requesting document in popup", err);
      setTags([]);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (open) {
      //console.log("Email before setting:", email);
      setSelectedDocumentRequestedStage(clientSubType || "All");
      fetchContacts();
      fetchStages();
      onRequestDocumentInPopupFunc();
    }
  }, [open]);

  useEffect(() => {
    if (selectedDocumentRequestedStage) {
      onRequestDocumentInPopupFunc();
    }
  }, [selectedDocumentRequestedStage, clientID]);

  const handleKeyDown = (e) => {
    if (e.key !== "Enter") return;
    const value = e.target.value.trim();
    if (!value) return;
    setTags([
      ...tags,
      {
        contact_id: clientID.id,
        document_name: value,
        document_category: "Recommended",
        email_id: email,
        user_id,
      },
    ]);
    e.target.value = "";
  };

  const removeTag = (index) => {
    setTags(tags.filter((_, i) => i !== index));
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setEmailErrors([]); // Clear errors on close
  };

  const filterOptions = createFilterOptions({
    matchFrom: "any",
    ignoreCase: true, // Ignore case sensitivity
    // stringify: (option) => option.service_sub_type_c,
  });

  const onRequestDocumentFormSubmit = async () => {
    setSubmitLoading(true);
    try {
      const requestDocument = await saveRequestedDocument({
        token,
        tags: tags.map((tag) => ({
          ...tag,
          email_id: email, // Ensure correct email is used here
        })),
      });
      if (requestDocument.code === 200) {
        setOpen(false);
        setCategoryUpdatedToTrue();
        setSubmitLoading(false);
        toast.success("Requested Documents are saved successfully", {
          position: "top-center",
          autoClose: 3000,
          theme: "dark",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        setOpen(false);
        setCategoryUpdatedToTrue();
        setSubmitLoading(false);
        toast.error("Failed to request documents", {
          position: "top-center",
          autoClose: 3000,
          theme: "dark",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (err) {
      console.error("Error saving requested documents", err);
      setSubmitLoading(false);
      toast.error("Failed to save requested documents", {
        position: "top-center",
        autoClose: 3000,
        theme: "dark",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  //console.log(email);

  return (
    <div className="dialog-modal">
      <ButtonUI
        formButton={false}
        variant="contained"
        buttonText="Request Documents"
        style={{
          width: "177px",
          height: "35px",
          color: "#fff",
          marginRight: "10px",
          backgroundColor: "#f6bf01",
          boxShadow: "none",
          fontWeight: "200",
          borderRadius: "4px",
          letterSpacing: "0.07rem",
        }}
        onClickFunc={handleClickOpen}
      />
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          className="edit-doc-modal"
          onClose={handleClose}
        >
          <div className="popup-header-svg-icon">
            <AiOutlineCloudUpload />
          </div>
          <div className="popup-header-title">Request Document</div>
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <div className="request-doc-top-section gap-6 flex flex-col lg:flex-row">
            <FormControl className="request-doc-select-ui">
              {/* <InputLabel
								className="request-doc-select-label"
								id="select-label"
							>
								Services
							</InputLabel> */}
              <Autocomplete
                id="services-autocomplete"
                options={stagesList}
                getOptionLabel={(option) => option.document_stage}
                filterOptions={filterOptions}
                value={
                  stagesList.find(
                    (stage) =>
                      stage.service_sub_type_c ===
                      selectedDocumentRequestedStage
                  ) || null
                }
                onChange={(event, newValue) => {
                  setSelectedDocumentRequestedStage(
                    newValue ? newValue.service_sub_type_c : ""
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Services"
                    variant="outlined"
                    sx={{ margin: "10px 0px", maxWidth: "20" }}
                  />
                )}
              />
            </FormControl>
          </div>
          <div className="tags-input-container">
            <div className="tags-input-content">
              {!loading ? (
                <>
                  {tags.map((tag, index) => (
                    <div className="tag-item" key={index}>
                      <span className="text">{tag.document_name}</span>
                      <span className="close" onClick={() => removeTag(index)}>
                        <RiCloseCircleFill />
                      </span>
                    </div>
                  ))}
                  <input
                    onKeyDown={handleKeyDown}
                    placeholder="Type here ..."
                    type="text"
                    className="tags-input"
                  />
                </>
              ) : (
                "Loading Document List ..."
              )}
            </div>
          </div>
          <div className="request-doc-top-section gap-6 flex flex-col lg:flex-row mt-2">
            <TextField
              label="Email"
              variant="outlined"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              disabled={!isEmailEditable}
              fullWidth
              multiline
              sx={{ margin: "10px 0px", maxWidth: "20" }}
            />
          </div>
        </DialogContent>
        {emailErrors.length > 0 && (
          <div className="text-red-500 p-4 w-full ">
            <span className="text-md">{`Missing email ID for the following contacts:`}</span>
            <ul>
              {emailErrors.map((contact) => (
                <li key={contact.name}>{contact.name}</li>
              ))}
            </ul>
            <span className="text-blue-600 text-md underline">
              <a href={`/clients`} target="_blank">
                Update here
              </a>
            </span>
          </div>
        )}
        <DialogActions>
          <div className="popup_submit_btn">
            <ButtonUI
              loading={submitLoading}
              formButton={false}
              variant="contained"
              buttonText="Submit"
              disabled={tags.length === 0 || email === ""}
              autoFocus={true}
              onClickFunc={onRequestDocumentFormSubmit}
            />
          </div>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};

export default RequestDocumentsPopup;
