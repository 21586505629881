import { sugarUrl } from "../config/baseUrl";

const getAllTaskTypeList = async ({ token }) => {
    try {
        const response = await fetch(
            `${sugarUrl}/get-task-type-list?category=Immigration`,
            {
                method: "POST",
                // headers: {
                //     Accept: "application/json",
                //     Authorization: `Bearer ${token}`,
                // },
            }
        );
        const data = await response.json();
        return data;
    } catch (error) {
        return error;
    }
};

export default getAllTaskTypeList;
