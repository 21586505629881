//Dev
const baseUrl = "https://dmsapi.consumerlaw.com/public";

export const sugarUrl = "https://sugar.consumerlaw.com/rest/v11_20";

//export const sugarUrl = "https://uat.consumerlaw.com/rest/v11_20";
//const baseUrl = "https://admindms.consumerlaw.com/public";

//Prod
//const baseUrl = "https://admindms.consumerlaw.com/public";

export default baseUrl;
