import { sugarUrl } from "../config/baseUrl";

const getAllCaseDetails = async ({
    caseParams
  }) => {

    try {
      const response = await fetch(`${sugarUrl}/get-case-details`, {
        method: "POST",

        body: JSON.stringify(caseParams),
      });
      const data = await response.json();
      return data;
    } catch (error) {
      return error;
    }
  };

  export default getAllCaseDetails;

