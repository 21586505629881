import { sugarUrl } from "../config/baseUrl";

const getOptinInfo = async ({ id }) => {
	try {
		const response = await fetch(
			`${sugarUrl}/check-user-optin`,
			{
				method: "POST",
				body: JSON.stringify({
					id,
				}),
			}
		);

		if (!response.ok) {
			throw new Error(`HTTP error! Status: ${response.status}`);
		}

		const data = await response.json();
		return data;
	} catch (error) {
		console.error("Error fetching template description:", error);
		return error;
	}
};

export default getOptinInfo;
