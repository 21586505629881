import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../context/Auth/AuthContext";
import baseUrl from "../../config/baseUrl";
import StatusPopup from "./StatusPopup";
import EditDocumentRequestSettingsPopup from "./EditDocumentRequestSettingsPopup";
import DeleteDocumentRequestSettingsPopup from "./DeleteDocumentRequestSettingsPopup";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import documentRequestSettingsData from "../../api/documentRequestSettings";
import AddDocumentRequestSettings from "./AddDocumentRequestSettings";
import getAllTypeList from "../../api/getAllFieldTypeList";
import "../../styles/Table.css";
import BulkDeletePopup from "./BulkDeletePopup";
import TableSkeleton from "../common/TableSkeleton";
import PaginationSection from "../common/PaginationSection";
import DataToolbar from "../common/DataToolbar";

import {
  DataGrid,
  GridToolbar,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid/";

import Box from "@mui/material/Box";

import {
  CaretSortIcon,
  ChevronDownIcon,
  MixerHorizontalIcon,
  StarIcon,
  StarFilledIcon,
} from "@radix-ui/react-icons";

import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../shadcn-components/ui/select";

import {
  ColumnDef,
  ColumnFiltersState,
  SortingState,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";

import { Button } from "../../shadcn-components/ui/button";
import { Checkbox } from "../../shadcn-components/ui/checkbox";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../../shadcn-components/ui/dropdown-menu";
import { Input } from "../../shadcn-components/ui/input";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../shadcn-components/ui/table";
import { fileIcon } from "../common/FileIcons";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../shadcn-components/ui/tooltip";
import { useDebounce } from "use-debounce";

/*** Component for listing all the document requests settings ***/
const DocumentRequestSettingsTable = ({
  addDocumentTab,
  showAddDocumentRequestSettingsForm,
  onAddDocumentRequestSettingsButtonClick,
  typeOptions,
  setTypeOptions,
}) => {
  const [loading, setLoading] = useState(false);
  const type = "client_type";
  const [documentRequestSettingsAdded, setDocumentRequestSettingsAdded] =
    useState(0);

  const { token, user_id, is_admin } = useContext(AuthContext);
  const [documentRequestSettingsResponse, setDocumentRequestSettingsResponse] =
    useState([]);

  const [bulkDeleteRequestSetting, setBulkDeleteRequestSetting] =
    useState(false);
  const [bulkDeleteRequestSettingIds, setBulkDeleteRequestSettingIds] =
    useState();

  const [sorting, setSorting] = useState([]);
  const [columnFilters, setColumnFilters] = useState([]);
  const [columnVisibility, setColumnVisibility] = useState({});
  const [rowSelection, setRowSelection] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [globalFilter, setGlobalFilter] = useState("");
  const [debouncedGlobalFilter] = useDebounce(globalFilter, 1000);

  const [selectAllChecked, setSelectAllChecked] = useState();

  const [showFilterButton, setShowFilterButton] = useState(false);

  const DocumentRequestSettingsResponseFunc = async () => {
    setLoading(true);
    const offset = currentPage - 1;
    const filterParams = {};

    columnFilters.forEach((filterItem) => {
      filterParams[filterItem.id] = filterItem.value;
    });

    const sortParam = sorting
      .map((sortItem) => {
        return `${sortItem.id}=${sortItem.desc ? "desc" : "asc"}`;
      })
      .join(",");

    const params = {
      isAdmin: is_admin,
      userId: user_id,
      limit: pageSize,
      offset: offset,
      ...filterParams,
      global_search: debouncedGlobalFilter,
      sort: sortParam,
    };
    const queryString = new URLSearchParams(params).toString();
    const url = `${baseUrl}/api/get-document-req-settings-data?${queryString}`;
    const response = await documentRequestSettingsData({ token, api: url });
    if (response.code == 200) {
      setTotalPages(Math.ceil(response?.data[0].document_count / pageSize));

      setDocumentRequestSettingsResponse(response?.data[0].all_document);
    } else {
      setTotalPages(0);
      setDocumentRequestSettingsResponse([]);
    }
    setLoading(false);
  };

  const clientTypeLoad = async () => {
    const data = await getAllTypeList({ token, type });
    if (data.code == 200) {
      const filteredTypeList =
        data?.data !== null
          ? data?.data.map((type) => {
              return {
                label: type.fields_name,
                typeId: type.field_setting_id,
              };
            })
          : [];
      setTypeOptions(filteredTypeList);
    } else {
      setTypeOptions([]);
    }
  };

  useEffect(() => {
    clientTypeLoad();
  }, []);

  useEffect(() => {
    setSelectAllChecked(false);
    DocumentRequestSettingsResponseFunc();
  }, [
    documentRequestSettingsAdded,
    bulkDeleteRequestSetting,
    debouncedGlobalFilter,
    currentPage,
    columnFilters,
    pageSize,
    sorting,
  ]);

  const setDocumentRequestSettingsAddedToTrue = () => {
    setDocumentRequestSettingsAdded((count) => count + 1);
  };

  const handleBulkAction = async (actionType) => {
    const selectedRowsData = table
      .getFilteredSelectedRowModel()
      .rows.map((row) => row.original);
    const ids = selectedRowsData
      .map((row) => row.document_request_setting_id)
      .join(",");
    if (actionType === "delete") {
      setBulkDeleteRequestSettingIds(ids);
      setBulkDeleteRequestSetting(true);
    }
  };

  const columns = [
    {
      id: "select",
      header: ({ table }) => (
        <div className="flex items-center">
          <Checkbox
            checked={
              table.getIsAllPageRowsSelected() ||
              (table.getIsSomePageRowsSelected() && "indeterminate")
            }
            onCheckedChange={(value) =>
              table.toggleAllPageRowsSelected(!!value)
            }
            aria-label="Select all"
          />
          {(table.getIsSomePageRowsSelected() ||
            table.getIsAllPageRowsSelected()) && (
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button
                  variant="outline"
                  className="p-0 !border-none !shadow-none bg-transparent"
                >
                  <ChevronDownIcon className="" />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end">
                <DropdownMenuItem onSelect={() => handleBulkAction("delete")}>
                  Delete
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          )}
        </div>
      ),
      cell: ({ row }) => (
        <Checkbox
          checked={row.getIsSelected()}
          onCheckedChange={(value) => {
            setSelectAllChecked(true);
            row.toggleSelected(!!value);
          }}
          aria-label="Select row"
        />
      ),
      enableSorting: false,
      enableHiding: false,
    },

    {
      accessorKey: "document_name",
      displayName: "Document Request Name",
      filterable: true,
      header: ({ column }) => (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          className="p-0 "
        >
          Document Request Name
          <CaretSortIcon className="ml-2 h-4 w-4" />
        </Button>
      ),
      cell: ({ row }) => (
        <div
          className={
            row.original.sample_document_link
              ? "capitalize text-blue-500 underline cursor-pointer"
              : "capitalize"
          }
          {...(row.original.sample_document_link
            ? { onClick: () => addDocumentTab(row.original) }
            : {})}
        >
          {row.getValue("document_name")}
        </div>
      ),
      filterFn: (row, id, value) => {
        return value.includes(row.getValue(id));
      },
    },
    // {
    // 	accessorKey: "sample_document_link",
    // 	displayName: "File",
    // 	header: ({ column }) => (
    // 		<Button
    // 			variant="ghost"
    // 			onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
    // 			className="p-0"
    // 		>
    // 			File
    // 			<CaretSortIcon className="ml-2 h-4 w-4" />
    // 		</Button>
    // 	),
    // 	cell: ({ row }) => (
    // 		<div className=" w-full flex items-center">
    // 			{row.getValue("sample_document_link")
    // 				? fileIcon[
    // 						row
    // 							.getValue("sample_document_link")
    // 							.substring(
    // 								row.getValue("sample_document_link").lastIndexOf(".") + 1
    // 							)
    // 				  ]
    // 				: null}
    // 		</div>
    // 	),
    // 	filterFn: (row, id, value) => {
    // 		return value.includes(row.getValue(id));
    // 	},
    // },
    {
      accessorKey: "fields_name",
      displayName: "Client Type",
      filterable: true,
      header: ({ column }) => (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          className="p-0"
        >
          Client Type
          <CaretSortIcon className="ml-2 h-4 w-4" />
        </Button>
      ),
      cell: ({ row }) => (
        <div className="capitalize">{row.getValue("fields_name")}</div>
      ),
      filterFn: (row, id, value) => {
        return value.includes(row.getValue(id));
      },
    },
    {
      accessorKey: "client_subtype",
      displayName: "Client Sub Type",
      filterable: true,
      header: ({ column }) => (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          className="p-0"
        >
          Client Sub Type
          <CaretSortIcon className="ml-2 h-4 w-4" />
        </Button>
      ),
      cell: ({ row }) => (
        <div className="capitalize">{row.getValue("client_subtype")}</div>
      ),
      filterFn: (row, id, value) => {
        return value.includes(row.getValue(id));
      },
    },
    {
      accessorKey: "document_stage",
      displayName: "Service",
      filterable: true,
      header: ({ column }) => (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          className="p-0"
        >
          Service
          <CaretSortIcon className="ml-2 h-4 w-4" />
        </Button>
      ),
      cell: ({ row }) => (
        <div className="capitalize">{row.getValue("document_stage")}</div>
      ),
      filterFn: (row, id, value) => {
        return value.includes(row.getValue(id));
      },
    },
    {
      accessorKey: "request_type",
      displayName: "Request Type",
      filterable: true,
      header: ({ column }) => (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          className="p-0"
        >
          Request Type
          <CaretSortIcon className="ml-2 h-4 w-4" />
        </Button>
      ),
      cell: ({ row }) => (
        <div className="capitalize">{row.getValue("request_type")}</div>
      ),
      filterFn: (row, id, value) => {
        return value.includes(row.getValue(id));
      },
    },
    {
      accessorKey: "status",
      displayName: "Status",
      filterable: true,
      header: ({ column }) => (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          className="p-0"
        >
          Status
          <CaretSortIcon className="ml-2 h-4 w-4" />
        </Button>
      ),
      cell: ({ row }) => (
        <div className="capitalize">{row.getValue("status")}</div>
      ),
      filterFn: (row, id, value) => {
        return value.includes(row.getValue(id));
      },
    },
    {
      accessorKey: "updated_at",
      displayName: "Modified At",
      header: ({ column }) => (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          className="p-0"
        >
          Modified At
          <CaretSortIcon className="ml-2 h-4 w-4" />
        </Button>
      ),
      cell: ({ row }) => (
        <div className="capitalize">{row.getValue("updated_at")}</div>
      ),
      filterFn: (row, id, value) => {
        return value.includes(row.getValue(id));
      },
    },
    {
      id: "actions",
      header: ({ column }) => (
        <Button variant="ghost" className="p-0 !cursor-default">
          Actions
        </Button>
      ),
      cell: ({ row }) => (
        <div className="flex gap-px">
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger>
                <span>
                  <StatusPopup
                    setDocumentRequestSettingsAddedToTrue={
                      setDocumentRequestSettingsAddedToTrue
                    }
                    tableValues={row.original}
                  />
                </span>
              </TooltipTrigger>
              <TooltipContent className="bg-gray-600">
                <p>Change Status</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>

          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger>
                <span>
                  <EditDocumentRequestSettingsPopup
                    setDocumentRequestSettingsAddedToTrue={
                      setDocumentRequestSettingsAddedToTrue
                    }
                    tableValues={row.original}
                    typeOptions={typeOptions}
                  />
                </span>
              </TooltipTrigger>
              <TooltipContent className="bg-gray-600">
                <p>Edit</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>

          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger>
                <span>
                  <DeleteDocumentRequestSettingsPopup
                    setDocumentRequestSettingsAddedToTrue={
                      setDocumentRequestSettingsAddedToTrue
                    }
                    id={row.original.document_request_setting_id}
                    setRowSelection={setRowSelection}
                  />
                </span>
              </TooltipTrigger>
              <TooltipContent className="bg-gray-600">
                <p>Delete</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
      ),
      enableHiding: false,
    },
  ];

  const table = useReactTable({
    data: documentRequestSettingsResponse,
    columns,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    initialState: {
      pageSize: 20,
    },
    manualPagination: true,
    manualSorting: true,
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      rowSelection,
      pageSize,
    },

    globalFilterFn: (rows, columnFilters) => {
      const [globalFilter] = columnFilters.filter(
        (filter) => filter.id === "global"
      );
      if (!globalFilter || !globalFilter.value) return rows;

      return rows.filter((row) => {
        const name = row.original.name.toLowerCase(); // Assuming 'name' is the column key
        return name.includes(globalFilter.value.toLowerCase());
      });
    },
    onGlobalFilterChange: (filterValue) => {
      setGlobalFilter(filterValue); // Update the global filter state
    },
  });

  return (
    <>
      {bulkDeleteRequestSetting ? (
        <BulkDeletePopup
          RequestSettingbulkid={bulkDeleteRequestSettingIds}
          bulkDeleteRequestSetting={bulkDeleteRequestSetting}
          setBulkDeleteRequestSetting={setBulkDeleteRequestSetting}
          setRowSelection={setRowSelection}
        />
      ) : null}
      <div className="user-table">
        {showAddDocumentRequestSettingsForm ? (
          <AddDocumentRequestSettings
            typeOptions={typeOptions}
            onAddDocumentRequestSettingsButtonClick={
              onAddDocumentRequestSettingsButtonClick
            }
            setDocumentRequestSettingsAddedToTrue={
              setDocumentRequestSettingsAddedToTrue
            }
          />
        ) : (
          <div className="document__table mt-3">
            <div className="w-full bg-white px-2">
              <div className="flex justify-between items-center py-4 flex-wrap gap-4">
                <Input
                  placeholder="Search..."
                  value={globalFilter}
                  onChange={(event) =>
                    table.setGlobalFilter(event.target.value)
                  }
                  className="max-w-sm"
                />
                <div className="flex gap-2">
                  <Button
                    variant="outline"
                    className=""
                    onClick={() => setShowFilterButton(!showFilterButton)}
                  >
                    <MixerHorizontalIcon className="mr-1" />
                    Filter
                  </Button>

                  <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                      <Button variant="outline" className="ml-auto">
                        Columns <ChevronDownIcon className="ml-2 h-4 w-4" />
                      </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent align="end">
                      {table
                        .getAllColumns()
                        .filter((column) => column.getCanHide())
                        .map((column) => {
                          return (
                            <DropdownMenuCheckboxItem
                              key={column.id}
                              className="capitalize"
                              checked={column.getIsVisible()}
                              onCheckedChange={(value) =>
                                column.toggleVisibility(!!value)
                              }
                            >
                              {column.columnDef.displayName}
                            </DropdownMenuCheckboxItem>
                          );
                        })}
                    </DropdownMenuContent>
                  </DropdownMenu>
                </div>
              </div>
              {showFilterButton && (
                <DataToolbar
                  table={table}
                  apiUrl="/api/get-requestsettings-filter-name"
                />
              )}
              <div className="rounded-md border">
                <Table>
                  <TableHeader className="pb-4 sticky top-0 bg-white z-[1]">
                    {table.getHeaderGroups().map((headerGroup) => (
                      <TableRow key={headerGroup.id}>
                        {headerGroup.headers.map((header) => {
                          return (
                            <TableHead key={header.id}>
                              {header.isPlaceholder
                                ? null
                                : flexRender(
                                    header.column.columnDef.header,
                                    header.getContext()
                                  )}
                            </TableHead>
                          );
                        })}
                      </TableRow>
                    ))}
                  </TableHeader>
                  <TableBody>
                    {table.getRowModel().rows?.length ? (
                      table.getRowModel().rows.map((row) => (
                        <TableRow
                          key={row.id}
                          data-state={row.getIsSelected() && "selected"}
                        >
                          {row.getVisibleCells().map((cell) => (
                            <TableCell key={cell.id}>
                              {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext()
                              )}
                            </TableCell>
                          ))}
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell
                          colSpan={columns.length}
                          className="h-24 text-center"
                        >
                          {loading ? <TableSkeleton /> : "no records found"}
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </div>
              <div className="flex items-center justify-between space-x-2 py-4 flex-wrap gap-4">
                <div className="flex items-center gap-5">
                  <div className="flex-1 text-sm text-muted-foreground">
                    {table.getFilteredSelectedRowModel().rows.length} of{" "}
                    {table.getFilteredRowModel().rows.length} row(s) selected.
                  </div>
                  <div className="flex items-center space-x-2">
                    <p className="text-sm font-medium">Rows per page</p>
                    <Select
                      value={`${table.getState().pageSize}`}
                      onValueChange={(value) => {
                        setPageSize(Number(value));
                        setCurrentPage(1);
                      }}
                    >
                      <SelectTrigger className="w-auto">
                        <SelectValue placeholder={table.getState().pageSize} />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectGroup>
                          {[10, 20, 30, 40, 50].map((pageSize) => (
                            <SelectItem key={pageSize} value={`${pageSize}`}>
                              {pageSize}
                            </SelectItem>
                          ))}
                        </SelectGroup>
                      </SelectContent>
                    </Select>
                  </div>
                </div>

                <div className="space-x-2">
                  <PaginationSection
                    setCurrentPage={setCurrentPage}
                    totalPages={totalPages}
                    currentPage={currentPage}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default DocumentRequestSettingsTable;
