import { sugarUrl } from "../config/baseUrl";

const getAllSugarUsers = async ({ token }) => {
  try {
    const response = await fetch(
      `${sugarUrl}/getdms-users-list`,
      {
        method: "GET",
      }
    );
    const data = await response.json();
    return data;
  } catch (error) {
    return error;
  }
};

export default getAllSugarUsers;