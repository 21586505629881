import { Bar, BarChart, CartesianGrid, LabelList, XAxis } from "recharts";

import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "../../shadcn-components/ui/card";
import {
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "../../shadcn-components/ui/chart";
import { useEffect, useState } from "react";
import getProcessedUserCount from "../../api/getProcessedUserCount";
import "../../styles/Dashboard.css";

const availableColors = [
  "hsl(var(--chart-1))", // Color for user 1
  "hsl(var(--chart-2))", // Color for user 2
  "hsl(var(--chart-3))", // Color for user 3
  "hsl(var(--chart-4))", // Color for user 4
  "hsl(var(--chart-5))", // Color for other
];

export function BarChartComponent({ token, customBoxShadow }) {
  const [chartData, setChartData] = useState([]);
  const [chartConfig, setChartConfig] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      const res = await getProcessedUserCount({ token });
      const users = res.data;

      const sortedUsers = users.sort(
        (a, b) => b.document_count - a.document_count
      );

      const top4Users = sortedUsers.slice(0, 4);
      const otherUsers = sortedUsers.slice(4);

      const otherDocumentCount = otherUsers.reduce(
        (sum, user) => sum + user.document_count,
        0
      );

      const newChartData = [
        ...top4Users.map((user, index) => ({
          assign_user: user.assign_user,
          document_count: user.document_count,
          fill: availableColors[index],
        })),
        {
          assign_user: "Other",
          document_count: otherDocumentCount,
          fill: availableColors[4],
        },
      ];

      const newChartConfig = {
        ...top4Users.reduce((acc, user, index) => {
          acc[user.assign_user] = {
            label: user.assign_user,
            color: availableColors[index],
          };
          return acc;
        }, {}),
        Other: {
          label: "Other",
          color: availableColors[4],
        },
      };

      setChartData(newChartData);
      setChartConfig(newChartConfig);
      setLoading(false);
    }

    fetchData();
  }, [token]);

  return (
    <Card style={customBoxShadow}>
      <CardHeader>
        <CardTitle>Bar Chart</CardTitle>
        <CardDescription>User Processed Cases</CardDescription>
      </CardHeader>
      <CardContent>
        <ChartContainer config={chartConfig}>
          <BarChart
            accessibilityLayer
            data={chartData}
            margin={{
              top: 20,
            }}
          >
            <CartesianGrid vertical={false} />
            <XAxis
              dataKey="assign_user"
              tickLine={false}
              tickMargin={10}
              axisLine={false}
              tickFormatter={(value) => {
                if (value && value.length > 5) {
                  return `${value.slice(0, 5)}...`;
                }
                return value;
              }}
            />
            <ChartTooltip cursor={false} content={<ChartTooltipContent />} />
            <Bar
              dataKey="document_count"
              fill="var(--color-desktop)"
              radius={8}
            >
              <LabelList
                position="top"
                offset={12}
                className="fill-foreground"
                fontSize={14}
              />
            </Bar>
          </BarChart>
        </ChartContainer>
      </CardContent>
    </Card>
  );
}
