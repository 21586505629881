import { sugarUrl } from "../config/baseUrl";

const sendSms = async ({
	id,
	message,
	messageTemplate,
	toNumber,
	channel,
	media,
	checkCondition,
	templateId,
}) => {
	try {
		const response = await fetch(
			`${sugarUrl}/send-dms-message`,
			{
				method: "POST",
				body: JSON.stringify({
					id,
					message,
					messageTemplate,
					toNumber,
					channel,
					media,
					checkCondition,
					templateId,
				}),
			}
		);

		if (!response.ok) {
			throw new Error(`HTTP error! Status: ${response.status}`);
		}

		const data = await response.json();
		return data;
	} catch (error) {
		console.error("Error fetching template description:", error);
		return error;
	}
};

export default sendSms;
